import React from 'react';
import ProfileForm from '../components/ProfileForm';




function ProfilePage() {
  return (
    <ProfileForm/>
  )
}

export default ProfilePage